// Utilities
import { make } from 'vuex-pathify'
import { genericRequest } from '@/api/modules'
import { getEmpleados, getEmpleado } from '@/routes/empleados'
import { empleadosMapper, empleadosHeader, empleadoMapper, empleadoHeader } from '@/mappers/empleados'
const state = {
  items: [],
  headers: empleadosHeader,
  empleado_header: empleadoHeader,
  empleado_selected: { fichadas: [] },
  count: 0,
  loading: true,
}

const mutations = make.mutations(state)

const actions = {

  async getData ({ commit, state, rootGetters }, data) {
    const { resources } = await genericRequest({
      url: getEmpleados,
      method: 'get',
    })
    if (resources) {
      const dataMapped = empleadosMapper(resources)

      commit('SET_COUNT', dataMapped.length)
      commit('SET_ITEMS', dataMapped)
    }

    commit('SET_LOADING', false)
  },

  async getEmpleado ({ commit, state }, query) {
    commit('SET_LOADING', true)
    commit('SET_EMPLEADO_SELECTED', { fichadas: [] })
    const resource = await genericRequest({
      url: `${getEmpleado}/${query.legajo}`,
      method: 'get',
    })

    if (resource) {
      const dataMapped = empleadoMapper(resource)

      commit('SET_EMPLEADO_SELECTED', dataMapped)
    }

    commit('SET_LOADING', false)
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
