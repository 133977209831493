<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="items"
    class="elevation-0 ml-2"
    locale="es-AR"
    :loading="loading"
    :height="height"
    :options="options"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    :show-select="showSelect"
    :item-key="itemKey"
    selectable-key="isSelectable"
    loading-text="Cargando... Por favor espere"
    no-data-text="La tabla se encuentra vacía"
    :expanded.sync="expanded"

    :show-expand="showExpand"
    :footer-props="{
      itemsPerPageText: 'Empleados por página',
      itemsPerPageOptions: [10,20, 50, 100, 150, 200, 250],
    }"

    @item-selected="changeItemSelected"
  >
    <template #[`header.data-table-select`]="{ }" />

    <template #[`item.data-table-expand`]="{ item, expand, isExpanded }" />

    <!-- Estado -->
    <template #[`item.estado`]="{ item }">
      <v-chip
        :color="item.estado | colorStatus"
        dark
        class="d-block text-center"
      >
        <div class="font-weight-regular text-caption">
          {{ item.estado }}
        </div>
      </v-chip>
    </template>

    <!-- Botones -->
    <template #[`item.actions`]="{ item }">
      <v-btn
        v-for="action in actions"
        :key="action.title"
        class="text-none font-weight-regular text-caption mx-1"
        :color="action.color"
        small
        dark
        @click="$emit(action.emit, item)"
      >
        <v-icon
          v-if="action.icon"
          small
          class="mr-2"
        >
          {{ action.icon }}
        </v-icon>
        {{ action.title }}
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
  import { colorStatus } from '@/mappers/status'
  export default {
    name: 'GenericTable',
    filters: {
      colorStatus,
    },
    props: {
      headers: {
        required: true,
        type: Array,
        default: function () {
          return []
        },
      },
      sortBy: {
        type: Array,
        default: function () {
          return ['idemcmc']
        },
      },
      sortDesc: {
        type: Array,
        default: function () {
          return [true]
        },
      },
      loading: {
        type: Boolean,
        default: false,
      },
      showExpand: {
        type: Boolean,
        default: false,
      },
      itemKey: {
        type: String,
        default: 'id',
      },
      showSelect: {
        type: Boolean,
        default: false,
      },
      height: { type: [String], default: '100%' },
      options: {
        type: Object,
        default: function () {
          return {
            page: 1,
            itemsPerPage: 10,
          }
        },
      },
      items: {
        type: Array,
        default: function () {
          return []
        },
      },
      actions: {
        type: Array,
        default: function () {
          return []
        },
      },
    },

    data: () => ({
      selected: [],
      idArticulos: [],
      expanded: [],
      singleExpand: false,
    }),
    computed: {
    },
    watch: {
    },

    methods: {
      checkItemSelected (id) {
        return this.idArticulos.includes(id)
      },
      changeItemSelected (data) {
        const indexItem = this.idArticulos.findIndex(e => {
          return e === data.item.id_articulo
        })

        if (data.value && data.item.disponible > 0) {
          if (indexItem === -1) {
            this.idArticulos.push(data.item.id_articulo)
          }
        } else {
          this.idArticulos.splice(indexItem, 1)
          data.item.disponible = data.item.disponibleOriginal
        }

        this.$emit('changeSelected', this.idArticulos)
      },
      handleDisponibleChange (item, value = null) {
        if (value) {
          item.disponible = value
        }
        if (item.disponible < 0) {
          item.disponible = 0
        }
        if (item.disponible > item.disponibleOriginal) {
          item.disponible = item.disponibleOriginal
        }

        item.reemplazo.forEach(itemInner => {
          const max = this.calcItemMax({ item, itemInner })
          if (itemInner.disponible > max) {
            itemInner.disponible = max
          }
        })
      },

      handleDisponibleInnerChange ({ item, itemInner }, value = null) {
        if (value) {
          itemInner.disponible = value
        }
        if (itemInner.disponible < 0) {
          itemInner.disponible = 0
        }
        if (itemInner.disponible > this.calcItemMax({ item, itemInner })) {
          itemInner.disponible = this.calcItemMax({ item, itemInner })
        }
      },
      calcItemMax ({ item, itemInner }) {
        let sumUsed = 0
        item.reemplazo.forEach(element => {
          if (element.id_artreemp !== itemInner.id_artreemp) {
            sumUsed += element.disponible
          }
        })

        const used = item.disponible + sumUsed

        const tot = item.disponibleOriginal - used

        return tot >= 0 ? tot : 0
      },
    },

  }
</script>

<style lang="sass">
.field-number
  width:155px!important
  .v-input__slot
     min-height: 35px!important
  .v-input__append-inner
     margin-top:7px!important
  .v-input__prepend-inner
     margin-top:7px!important
.inner-table
  th
    height: 7px!important
</style>
