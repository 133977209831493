const { camelCase } = require('lodash')

const statusColor = {
    vigente: 'vividBlue',
    presente: 'success',
    incompleto: 'orange',
    ausente: 'red',
}

export const colorStatus = (status) => {
    return statusColor[camelCase(status)]
}
