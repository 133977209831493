import { groupArrayBy } from '@/util/array'
import { shortDate } from '@/util/helpers'

const TODAY = new Date().toISOString().slice(0, 10)

export const calcularFichadas = (fichadas = []) => {
    const itemsGroup = groupArrayBy(fichadas.reverse(), 'Fichada_Fecha')

    const response = []

    Object.values(itemsGroup).forEach((item) => {
        const newItem = item[0]
        if (item[0]) {
            newItem.hora_entrada = item[0].Fichada_Hora
            if (item.length > 1) {
                newItem.hora_salida = item[item.length - 1].Fichada_Hora
            }
        }

        response.push(newItem)
    })
    return response.map((empleado) => ({
        reloj: empleado.Reloj_Id,
        hora_salida: empleado.hora_salida,
        hora_entrada: empleado.hora_entrada,
        fecha: shortDate(empleado.Fichada_Fecha),
        estado: mapperEstado(empleado),
    })).reverse()
}

const mapperEstado = (empleado) => {
    if (empleado.hora_entrada && empleado.hora_salida) {
        return 'Presente'
    }
    if (empleado.Fichada_Fecha === TODAY && !empleado.hora_salida) {
        return 'Vigente'
    }
    if (empleado.hora_entrada && !empleado.hora_salida) {
        return 'Incompleto'
    }
    if (!empleado.hora_entrada && !empleado.hora_salida) {
        return 'Ausente'
    }
}
