import { calcularFichadas } from './utils/empleado'

// const fichadas = require('@/jsons/examples/fichadas.json')

export const empleadosMapper = (items) => {
  return items.map((obj) => ({
    nombre: obj.per_nombre,
    apellido: obj.per_apellido,
    nombre_corto: obj.per_nombre_corto,
    legajo: obj.per_legajo,
    cuit: obj.per_cuil,
  }))
}

export const empleadoMapper = (empleado) => {
  return {
    fichadas: calcularFichadas(empleado.Fichadas),
  }
}

export const empleadosHeader = [
  {
    text: 'Legajo',
    align: 'start',
    sortable: false,
    value: 'legajo',
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',

  },
  {
    text: 'CUIT',
    align: 'start',
    value: 'cuit',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
  {
    text: 'Nombre',
    align: 'start',
    value: 'nombre',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
  {
    text: 'Apellido',
    align: 'start',
    value: 'apellido',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
  {
    text: 'Cargo',
    align: 'start',
    value: 'remitos_cant',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
  {
    text: 'B. horaria',
    align: 'start',
    value: 'remitos_cant',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
  {
    text: 'Reloj',
    align: 'start',
    value: 'remitos_cant',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
  {
    text: 'Fecha',
    align: 'start',
    value: 'remitos_cant',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
  {
    text: 'Hora entrada',
    align: 'start',
    value: 'remitos_cant',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
  {
    text: 'Hora salida',
    align: 'start',
    value: 'remitos_cant',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
  {
    text: 'Estado',
    align: 'start',
    value: 'remitos_cant',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
  {
    text: '',
    align: 'end',
    value: 'actions',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
  },
]

export const empleadoHeader = [

  {
    text: 'Reloj',
    align: 'start',
    value: 'reloj',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
  {
    text: 'Fecha',
    align: 'start',
    value: 'fecha',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
  {
    text: 'Hora entrada',
    align: 'start',
    value: 'hora_entrada',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
  {
    text: 'Hora salida',
    align: 'start',
    value: 'hora_salida',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
  {
    text: 'Estado',
    align: 'start',
    value: 'estado',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
]
