// Utilities
import { make } from 'vuex-pathify'

import { setCookie, getCookie } from '@/util/cookies'
const state = {
  dark: getCookie('mc_front_dark_mode'),
  loaded: false,
  proveedor: {},
  admin: {},
  cuit: '',
  avatar: '',
  name: '',
  group: null,
}

const mutations = make.mutations(state)

const actions = {

  // get user info
   getUserInfo ({ commit, state, dispatch }) {
    commit('SET_CUIT', '')
    commit('SET_NAME', '')
    commit('SET_PROVEEDOR', {})
    commit('SET_ADMIN', {})
    commit('SET_GROUP', null)
    const { cuit, proveedor, admin } = getCookie()
    commit('SET_CUIT', cuit)
    if (proveedor) {
      commit('SET_PROVEEDOR', { ...proveedor, name: proveedor.razsoc })
      commit('SET_NAME', proveedor.razsoc)
      commit('SET_GROUP', 'proveedor')
    }
    if (admin) {
      commit('SET_ADMIN', { ...admin, name: `${admin.nombre} ${admin.apellido}` })
      commit('SET_NAME', `${admin.nombre} ${admin.apellido}`)
      commit('SET_GROUP', 'admin')
    }
    commit('SET_AVATAR', '')
    commit('SET_LOADED', true)
  },

  changeDark ({ commit, state }) {
    commit('SET_DARK', !state.dark)
    setCookie(state.dark, 'mc_front_dark_mode')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
